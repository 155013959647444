


























import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

export default Vue.extend({
  name: 'ActionFeedbackDisplay',
  props: {
    actionFeedbackType: String,
  },
  data() {
    return {
      showAlert: true,
    }
  },
  computed: {
    ...mapGetters(['getActionFeedback']),
    actionFeedback(): string {
      return this.getActionFeedback[this.actionFeedbackType] || null
    },
  },
  watch: {
    actionFeedback: function () {
      setTimeout(() => this.closeClick(), 5000)
    },
  },
  methods: {
    ...mapActions(['resetActionFeedback']),
    closeClick(): void {
      this.showAlert = false
      setTimeout(() => {
        this.resetActionFeedback(this.actionFeedbackType)
        this.showAlert = true // reset alert state, alert will not show because actionFeedback as been nulled
      }, 1000)
    },
  },
})
