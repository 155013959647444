

















import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
// import * as firebase from 'firebase/app'
import ActionFeedbackDisplay from '@/components/ActionFeedbackDisplay.vue'

export default Vue.extend({
  name: 'Logout',
  components: {
    ActionFeedbackDisplay,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getUser']),
  },
  methods: {
    ...mapActions(['logOutUser']),
    logOutClick() {
      //confirm logout
      if (this.getUser) {
        this.logOutUser()
      }
    },
  },
  created() {
    // Confirm the link is a sign-in with email link.
    this.logOutUser()
  },
})
